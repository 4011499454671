export function createListViewComponent(_ref) {
  var createElement = _ref.createElement;
  return function ListView(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      ItemComponent = userProps.itemComponent,
      items = userProps.items,
      sendEvent = userProps.sendEvent;
    return createElement("div", {
      className: classNames.container
    }, createElement("ol", {
      className: classNames.list
    }, items.map(function (item) {
      return createElement("li", {
        key: item.objectID,
        className: classNames.item,
        onClick: sendEvent,
        onAuxClick: sendEvent
      }, createElement(ItemComponent, {
        item: item
      }));
    })));
  };
}